@tailwind base;
@tailwind components;
@tailwind utilities;

@import '/node_modules/flag-icons/css/flag-icons.min.css';

@layer utilities {
  .shadow-all-sides {
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  }

  .readonly-input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #d1d5db;
    border-radius: 0.375rem;
    background-color: #e5e7eb;
    color: #4a5568;
    cursor: not-allowed;
    outline: none;
  }

  .readonly-input:focus {
    outline: none;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.footer-gradient {
  background: linear-gradient(to right, #ad4e04, #fd7e23);
}

.offer-bg {
  background-image: url('./assets/offer-bg.png');
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;
}

.youtubeChannel-bg {
  background-image: url('./assets/youtubeChannelBg.png');
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat;
}

#fullname,
#email,
/* #subject, */
/* #message, */
#number {
  margin-top: 0.5rem;
  border-bottom: 1px solid #ccc;
  border-radius: 0px;
  outline: none;
}

.contactSubject__control {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: 0 !important;
}

.contactSubject__indicator-separator {
  display: none !important;
}

.contactSubject__value-container {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

#message {
  outline: none !important;
}

/* #googleSignIn > :nth-child(1) {
  background-color: pink;
  flex: content;
}

#googleSignIn span {
  flex-grow: unset !important;
}

#googleSignIn .nsm7Bb-HzV7m-LgbsSe-bN97Pc-sM5MNb {
  justify-content: center !important;
}

.haAclf,
#googleSinIn iframe {
  width: 100%;
  margin: 0 !important;
  padding: 0 !important;
}

.haAclf > :nth-child(1) {
  max-width: none !important;
}

#googleSignIn .nsm7Bb-HzV7m-LgbsSe-BPrWId {
  flex-grow: unset !important;
}
*/

.password-input-container {
  position: relative;
}

.password-input-container input {
  padding-right: 35px;
}

.password-input-container svg {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

.reg-success:focus {
  outline: none !important;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loader-spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #ffffff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

#table-row td:first-child .w-28 {
  width: 10px;
  margin-right: 15px;
}

.pagination li a {
  /* border-radius: 7px; */
  padding: 0.1rem 1rem;
  font-weight: 600;
  /* border: gray 1px solid;
  cursor: pointer; */
}
.pagination li.previous a,
.pagination li.next a,
.pagination li.break a {
  border-color: transparent;
}
.pagination li.active a {
  /* background-color: #0366d6;
  border-color: transparent; */
  color: #0366d6;
  min-width: 32px;
}
.pagination li.disabled a {
  color: grey;
}
.pagination li.disable,
.pagination li.disabled a {
  cursor: default;
}

.error-icon {
  fill: red;
}

.bar {
  width: 100%;
  height: 6px;
  background-color: #e5e7eb;
  position: relative;
}

.active-tab #review-count {
  background-color: #fd8323 !important;
}

.truncate-multiline {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

#pdf-download {
  display: none; /* Specifically hides download buttons */
}

#privacy-ol {
  list-style-type: decimal;
}

#privacy-ul {
  list-style-type: disc;
}

#privacy-ul-alphabets {
  list-style-type: lower-alpha;
}

#privacy-ol,
#privacy-ul,
#privacy-ul-alphabets {
  list-style-position: inside;
  margin-left: 20px;
}

#privacy-ol li,
#privacy-ul li,
#privacy-ul-alphabets li {
  margin-top: 5px;
}

@media (min-width: 640px) and (max-width: 1024px) {
  .truncate-single-line {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (min-width: 640px) {
  #why-we-do-what-we-do {
    text-align: center;
    max-width: 900px;
    margin: auto;
    margin-bottom: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 900px) {
  #visionary-lg {
    display: none;
  }

  #visionary-sm {
    display: block;
  }
}

@media screen and (min-width: 901px) {
  #visionary-sm {
    display: none;
  }
}

/* @media (min-width: 1280px) {
  .upcoming-session {
    max-height: 300px !important;
  }
} */

.rpv-core__textbox {
  width: 50px !important;
}
